<template>
  <BModal title="Create a new group" centered="true" hide-footer="true">
    <div class="row justify-content-center">
      <div class="form-group col-12">
        <label for="name"><b>Group name:</b></label>
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control shadow-none"
            name="name"
            placeholder="Enter group name"
            v-model="name"
          />
          <div class="input-group-append">
            <span class="input-group-text input-img-span"
              ><img src="../../assets/name-icon.png" class="input-img" alt=""
            /></span>
          </div>
        </div>
      </div>

      <div class="form-group col-12">
        <label for="email"><b>Select members:</b></label>
        <div class="input-group mb-3">
          <Multiselect
            :multiple="true"
            v-model="selectedTrainees"
            :options="trainees"
            :searchable="false"
            :close-on-select="false"
            :show-labels="false"
            placeholder="Pick a value"
            label="name"
            track-by="name"
          ></Multiselect>
        </div>
      </div>

      <div class="col-12 mt-3">
        <div v-if="error" class="alert alert-danger mx-2" role="alert">
          Error!
        </div>
        <button class="btn-block w-100 primary-button" @click="addGroup">
          <b>Create Group</b>
        </button>
      </div>
    </div>
  </BModal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { BModal } from "bootstrap-vue-3";
import Multiselect from "vue-multiselect";

export default {
  name: "AddGroupModal",
  components: {
    BModal,
    Multiselect,
  },

  data() {
    return {
      name: "",
      selectedTrainees: null,
      error: false,
    };
  },

  computed: {
    ...mapGetters(["trainees"]),
  },

  methods: {
    ...mapActions(["createGroup", "getUsers"]),

    addGroup() {
      let groupName = this.name;
      let memberIds = [];

      this.selectedTrainees.forEach((trainee) => {
        memberIds.push(trainee.id);
      });

      if (!groupName) return;

      this.createGroup({ name: groupName, members: memberIds })
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
          this.error = true;
        });
    },
  },

  mounted() {
    this.getUsers();
  },
};
</script>

<style scoped>
.input-img-span {
  background-color: white !important;
  border-radius: 0 0.375rem 0.375rem 0 !important;
  border-left: none;
}

input[type="text"],
input[type="password"] {
  border-right: none;
}

input:focus {
  border: 1px solid #2f2da4;
  outline: none !important;
}

.input-img {
  height: 1.5rem;
}
</style>
