<template>
  <div class="container">
    <div class="row">
      <div class="col-12 d-flex justify-content-between mb-5 mt-3">
        <div>
          <h3>
            <b>Grupa: {{ currentUser?.organization.name }}</b>
          </h3>
        </div>
        <div class="user-name d-flex">
          <p class="mx-auto my-auto">{{ currentUser?.name }}</p>
        </div>
      </div>
      <div class="col-12 px-0 mb-3 select-box">
        <div
          :class="'w-50 h-100 payments ' + (selected == 'payments' ? 'selected' : '')"
          @click="selected = 'payments'"
        >
          <p class="mt-2">Payments</p>
        </div>
        <div
          :class="'w-50 h-100 payments ' + (selected == 'comments' ? 'selected' : '')"
          @click="selected = 'comments'"
        >
          <p class="mt-2">Comments</p>
        </div>
      </div>
      <div class="col-12">
        <Comments v-if="selected == 'comments'" :comments="currentUser.comments" />
        <Payments v-if="selected == 'payments'" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Comments from "../components/Trainees/Comments";
import Payments from "../components/Trainees/Payments";

export default {
  name: "UserInfo",
  components: { Comments, Payments },

  data() {
    return {
      selected: "comments",
    };
  },

  computed: {
    ...mapGetters(["currentUser"]),
  },

  methods: {
    ...mapActions(["getCurrentUser"]),
  },

  async mounted() {
    let userId = this.$route.params.id;

    await this.getCurrentUser(userId)
      .then(() => {
        console.log("USER", this.currentUser);
      })
      .catch((err) => {
        console.log(err);
        this.$router.push("/groups");
      });
  },
};
</script>

<style scoped>
.user-name {
  width: 158px;
  height: 53px;
  background: #eaeaf6;
  border: 1px solid #2f2da4;
  border-radius: 8px;
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 150%;
}

.select-box {
  height: 40px;
  background: #dfdfe3;
  border: 1px solid #9999a5;
  border-radius: 8px;
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #9999a5;
  cursor: pointer;
}

.selected {
  background: #2f2da4;
  color: #fff;
  box-shadow: 0px 2px 10px rgba(25, 1, 52, 0.12);
  border-radius: 8px;
}
</style>
