<template>
  <div class="container">
    <div class="row">
      <div class="col-12 d-flex justify-content-between mb-4 mt-3">
        <h3><b>Trainees</b></h3>
        <button class="primary-button" @click="modalShow = !modalShow">
          <b>Invite trainee</b>
        </button>
      </div>
      <div class="col-12">
        <TraineesTable />
      </div>
    </div>
  </div>
</template>

<script>
import TraineesTable from "../components/Trainees/TraineesTable";

export default {
  name: "Trainees",
  components: { TraineesTable },

  data() {
    return {
      modalShow: false,
    };
  },
};
</script>

<style></style>
