<template>
  <div class="container">
    <div class="row">
      <div class="col-12 d-flex justify-content-between mb-4 mt-3">
        <h3><b>Payments</b></h3>
      </div>
      <div class="col-12 mb-4">
        <div class="training px-3 py-2">
          <p class="mb-0"><b>Payment month</b></p>
          <h3 class="percentage mb-0">{{ 100 - paymentPercentage }}% to complete</h3>
          <div class="d-flex flex-row-reverse">
            <p class="mb-1 days-left">13 days left</p>
          </div>
          <div class="w-100 custom-progress-bar">
            <div class="finished-progress-bar" :style="'width:' + paymentPercentage + '%'"></div>
          </div>
        </div>
      </div>

      <Group v-for="(group, index) in groups" :key="index" :group="group" :showPayments="true" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Group from "../components/Groups/Group.vue";

export default {
  name: "Payments",
  components: { Group },

  data() {
    return {
      paymentPercentage: 33,
    };
  },

  computed: {
    ...mapGetters(["groups"]),
  },

  methods: {
    ...mapActions(["getGroups"]),
  },

  mounted() {
    this.getGroups();
  },
};
</script>

<style scoped>
.training {
  height: 120px;
  box-shadow: 0px 2px 10px rgba(25, 1, 52, 0.12);
  border-radius: 8px;
}

.percentage {
  color: #2f2da4;
  font-weight: 700;
}

.custom-progress-bar {
  height: 8px;
  background: #eaeaf6;
  border-radius: 500px;
}

.finished-progress-bar {
  height: 8px;
  background: #2f2da4;
  border-radius: 500px;
}

.days-left {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  color: #aaa9da;
}
</style>
