import axios from "axios";

const state = {
  user: sessionStorage.user ? JSON.parse(sessionStorage.user) : null,
  users: [],
  trainees: [],
  events: [],
  groups: [],
  currentGroup: null,
  currentUser: null,
};

const getters = {
  user: () => (sessionStorage.user ? JSON.parse(sessionStorage.user) : null),
  users: (state) => state.users,
  trainees: (state) => state.trainees,
  events: (state) => state.events,
  groups: (state) => state.groups,
  currentGroup: (state) => state.currentGroup,
  currentUser: (state) => state.currentUser,
};

const actions = {
  // AUTH
  async LOGIN({ commit }, userData) {
    let userAgent = navigator.userAgent.toLowerCase();
    let url = process.env.VUE_APP_API_BASE + "/token";

    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("email", userData.email);
      formData.append("password", userData.password);
      formData.append("device_name", userAgent);

      axios
        .post(url, formData)
        .then(({ data, status }) => {
          if (status === 200) {
            sessionStorage.user = JSON.stringify({ token: data.token });
            commit("login", { token: data.token });
            resolve({ data, status });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async REGISTER({ commit }, userData) {
    let userAgent = navigator.userAgent.toLowerCase();
    let url = process.env.VUE_APP_API_BASE + "/register";

    return new Promise((resolve, reject) => {
      let formData = new FormData();

      formData.append("name", userData.fullName);
      formData.append("organization_name", userData.organization);
      formData.append("email", userData.email);
      formData.append("password", userData.password);
      formData.append("device_name", userAgent);

      axios
        .post(url, formData)
        .then(({ data, status }) => {
          if (status === 200) {
            sessionStorage.user = JSON.stringify({ token: data.token });
            commit("register", { token: data.token });
            window.location.reload();
            resolve({ data, status });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // END AUTH

  // EVENTS ACTIONS
  async getEvents({ state, commit }) {
    let url = process.env.VUE_APP_API_BASE + "/events";
    let token = state.user?.token ?? null;

    if (!token) return;

    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then(({ data, status }) => {
          if (status === 200) {
            commit("events", data.events);
            resolve({ data, status });
          }
          console.log("EVENTS", data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createEvent({ state, commit }, eventData) {
    let url = process.env.VUE_APP_API_BASE + "/events";
    let token = state.user?.token ?? null;

    if (!token) return;

    return new Promise((resolve, reject) => {
      let formData = new FormData();

      formData.append("name", eventData.name);
      formData.append("description", eventData.description);
      formData.append("starts_at", eventData.startDate);
      formData.append("ends_at", eventData.endDate);

      for (var i = 0; i < eventData.groups.length; i++) {
        formData.append("groups[]", eventData.groups[i]);
      }

      axios
        .post(url, formData, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then(({ data, status }) => {
          if (status === 200) {
            commit("createEvent", eventData);
            resolve({ data, status });
          }
          console.log("ADDED EVENT", data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  // END EVENTS ACTIONS

  //USERS ACTIONS
  getUsers({ state, commit }) {
    let url = process.env.VUE_APP_API_BASE + "/users";
    let token = state.user?.token ?? null;

    if (!token) return;

    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then(({ data, status }) => {
          if (status === 200) {
            commit("users", data.users);

            let trainees = data.users.filter((user) => user.role == "member");
            console.log("TRAINEES", trainees);

            commit("trainees", trainees);

            resolve({ data, status });
          }
          console.log("USERS", data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getCurrentUser({ state, commit }, userId) {
    let url = process.env.VUE_APP_API_BASE + "/users/" + userId;
    let token = state.user?.token ?? null;

    if (!token) return;

    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then(({ data, status }) => {
          if (status === 200) {
            commit("currentUser", data);
            resolve({ data, status });
          }
          console.log("CURR_USER", data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  //END USER ACTIONS

  // GROUPS ACTIONS
  getGroups({ state, commit }) {
    let url = process.env.VUE_APP_API_BASE + "/groups";
    let token = state.user?.token ?? null;

    if (!token) return;

    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then(({ data, status }) => {
          if (status === 200) {
            commit("groups", data.groups);
            resolve({ data, status });
          }
          console.log("GROUPS", data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getCurrentGroup({ state, commit }, groupId) {
    let url = process.env.VUE_APP_API_BASE + "/groups/" + groupId;
    let token = state.user?.token ?? null;

    if (!token) return;

    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then(({ data, status }) => {
          if (status === 200) {
            commit("currentGroup", data);
            resolve({ data, status });
          }
          console.log("CURR_GROUP", data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createGroup({ state, commit }, groupData) {
    let url = process.env.VUE_APP_API_BASE + "/groups";
    let token = state.user?.token ?? null;

    if (!token) return;

    return new Promise((resolve, reject) => {
      let formData = new FormData();

      formData.append("name", groupData.name);

      for (var i = 0; i < groupData.members.length; i++) {
        formData.append("members[]", groupData.members[i]);
      }

      axios
        .post(url, formData, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then(({ data, status }) => {
          if (status === 200) {
            commit("createGroup", groupData);
            resolve({ data, status });
          }
          console.log("ADDED GROUP", data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
};
const mutations = {
  login: (state, user) => (state.user = { ...user }),
  register: (state, user) => (state.user = { ...user }),
  users: (state, users) => (state.users = users),
  events: (state, events) => (state.events = events),
  createEvent: (state, event) => state.events.push(event),
  groups: (state, groups) => (state.groups = groups),
  createGroup: (state, group) => state.groups.push(group),
  currentGroup: (state, currentGroup) => (state.currentGroup = currentGroup),
  trainees: (state, trainees) => (state.trainees = trainees),
  currentUser: (state, currentUser) => (state.currentUser = currentUser),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
