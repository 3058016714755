<template>
  <div class="container">
    <div class="row">
      <div class="col-12 d-flex justify-content-between mb-4 mt-3">
        <h3><b>All Trainings</b></h3>
        <button class="primary-button" @click="modalShow = !modalShow">
          <b>Add Training</b>
        </button>
      </div>
      <div class="col-12">
        <EventsSlider :days="daysArr" @changeDay="changeDay" />
      </div>

      <UpcomingTraining v-for="(training, index) in currentDayEvents" :key="index" :training="training" />

      <AddEventModal v-model="modalShow" :minDate="startDate" :maxDate="endDate" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import EventsSlider from "../components/Events/EventsSlider.vue";
import UpcomingTraining from "../components/Events/UpcomingTraining.vue";
import AddEventModal from "../components/Events/AddEventModal.vue";

export default {
  name: "AllTrainings",
  components: { EventsSlider, UpcomingTraining, AddEventModal },

  data() {
    return {
      startDate: new Date(Date.now() - 15 * 24 * 60 * 60 * 1000),
      endDate: new Date(Date.now() + 15 * 24 * 60 * 60 * 1000),
      daysArr: [],
      modalShow: false,
      currentDayEvents: [],
    };
  },

  computed: {
    ...mapGetters(["events", "groups"]),
  },

  methods: {
    ...mapActions(["getGroups", "getEvents"]),

    formatDate(date) {
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var seconds = String(date.getSeconds()).padStart(2, '0');

      return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
    },

    getDaysArray() {
      for (
        var arr = [], dt = new Date(this.startDate);
        dt <= new Date(this.endDate);
        dt.setDate(dt.getDate() + 1)
      ) {
        arr.push(new Date(dt));
      }
      return arr;
    },

    changeDay() {
      this.currentDayEvents = this.events.filter((event) => {
        this.formatDate(new Date()).split(' ') === event.starts_at.split(' ');
    });
    },
  },

  mounted() {
    this.daysArr = this.getDaysArray();
    this.getEvents();

    this.currentDayEvents = this.events.filter((event) => {
      this.formatDate(new Date()).split(' ') === event.starts_at.split(' ');
    });
  },
};
</script>

<style></style>
