import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home";
import Login from "../views/Login";
import Register from "../views/Register";
import Trainees from "../views/Trainees";
import Groups from "../views/Groups";
import Payments from "../views/Payments";
import Clubboard from "../views/Clubboard";
import ForgotPassword from "../views/ForgotPassword";
import AllTrainings from "../views/AllTrainings";
import Group from "../views/Group";
import UserInfo from "../views/UserInfo";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Klubmen",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Klubmen - Login",
    },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      title: "Klubmen - Register",
    },
  },
  {
    path: "/trainees",
    name: "Trainees",
    component: Trainees,
    meta: {
      title: "Klubmen - Trainees",
    },
  },
  {
    path: "/groups",
    name: "Groups",
    component: Groups,
    meta: {
      title: "Klubmen - Groups",
    },
  },
  {
    path: "/groups/:id",
    name: "Group",
    component: Group,
    meta: {
      title: "Klubmen - Groups",
    },
  },
  {
    path: "/users/:id",
    name: "UserInfo",
    component: UserInfo,
    meta: {
      title: "Klubmen - User Info",
    },
  },
  {
    path: "/payments",
    name: "Payments",
    component: Payments,
    meta: {
      title: "Klubmen - Payments",
    },
  },
  {
    path: "/clubboard",
    name: "Clubboard",
    component: Clubboard,
    meta: {
      title: "Klubmen - Clubboard",
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      title: "Klubmen - Forgot Password",
    },
  },
  {
    path: "/all-trainings",
    name: "AllTrainings",
    component: AllTrainings,
    meta: {
      title: "Klubmen - All Trainings",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : "Klubmen";

  next();
});

export default router;
