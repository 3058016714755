<template>
  <div class="row">
    <div class="col-12 d-flex justify-content-between mt-3">
      <div>
        <h3>
          <b>All comments</b>
        </h3>
      </div>
    </div>
    <div class="col-12 comment-section">comments</div>
  </div>
</template>

<script>
export default {
  name: "Comments",
  props: ["comments"],
};
</script>

<style scoped>
.comment-section {
  background: #eaeaf6;
  border: 1px solid #2f2da4;
  border-radius: 8px;
}
</style>
