<template>
  <div class="mb-3">
    <ul
      class="items d-flex justify-content-between"
      @mousedown="start"
      @touchstart="start"
      @mousemove="move"
      @touchmove="move"
      @mouseleave="end"
      @mouseup="end"
      @touchend="end"
    >
      <li class="item dashboard-1">
        <div class="w-100 h-100 d-flex"></div>
      </li>
      <li class="item dashboard-2"></li>
      <li class="item dashboard-3"></li>
      <li class="item dashboard-1"></li>
      <li class="item dashboard-2"></li>
      <li class="item dashboard-3"></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "DashboardSlider",
  data() {
    return {
      isDown: false,
      startX: null,
      scrollLeft: null,
    };
  },
  methods: {
    start(e) {
      const slider = document.querySelector(".items");
      this.isDown = true;
      this.startX = e.pageX || e.touches[0].pageX - slider.offsetLeft;
      this.scrollLeft = slider.scrollLeft;
    },

    end() {
      this.isDown = false;
    },

    move(e) {
      const slider = document.querySelector(".items");
      if (!this.isDown) return;

      e.preventDefault();

      const x = e.pageX || e.touches[0].pageX - slider.offsetLeft;
      const dist = x - this.startX;
      slider.scrollLeft = this.scrollLeft - dist;
    },
  },
};
</script>

<style scoped>
ul {
  padding: 0;
}

li {
  list-style: none;
}

.items {
  position: relative;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  font-size: 0;
  cursor: pointer;
}

.item {
  display: inline-block;
  margin: 0 10px;
  user-select: none;
  width: 50%;
  min-width: 152px;
  min-height: 180px;
  font-size: 33px;
  font-weight: bold;
  line-height: 130px;
  background: #2f2da4;
  box-shadow: 0px 4px 10px rgba(25, 1, 52, 0.16);
  border-radius: 16px;
}

.dashboard-1 {
  background-image: url("../assets/dashboard-img-1.png");
  background-repeat: no-repeat;
  background-size: 100%;
}

.dashboard-2 {
  background-image: url("../assets/dashboard-img-3.png");
  background-repeat: no-repeat;
  background-size: 100%;
}

.dashboard-3 {
  background-image: url("../assets/dashboard-img-2.png");
  background-repeat: no-repeat;
  background-size: 100%;
}

@media screen and (min-width: 500px) {
  .item {
    width: 33%;
  }
}

@media screen and (min-width: 800px) {
  .item {
    width: 25%;
  }
}

@media screen and (min-width: 1200px) {
  .item {
    width: 20%;
  }
}
</style>
