<template>
  <div class="container">
    <div class="row">
      <div class="col-12 mb-4 mt-3">
        <h3><b>Dashboard</b></h3>
        <DashboardSlider />
      </div>
      <div class="col-12 d-flex justify-content-between">
        <h3 @click="getEvents"><b>Upcoming trainings</b></h3>
        <router-link class="see-all" to="/all-trainings">See All</router-link>
      </div>
      <UpcomingTraining
        v-for="(training, index) in events"
        :key="index"
        :training="training"
      />
    </div>
  </div>
</template>

<script>
import DashboardSlider from "../components/DashboardSlider.vue";
import UpcomingTraining from "../components/Events/UpcomingTraining.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "Home",
  components: { DashboardSlider, UpcomingTraining },
  data() {
    return {};
  },

  computed: {
    ...mapGetters(["events"]),
  },

  methods: {
    ...mapActions(["getEvents"]),
  },

  mounted() {
    this.getEvents();
  },
};
</script>

<style>
.see-all {
  color: #2f2da4;
  background: #eaeaf6;
  border: 1px solid #2f2da4;
  border-radius: 8px;
  padding: 4px 8px;
  padding-top: 7px;
}
</style>
