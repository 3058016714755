<template>
  <div class="container">
    <div class="row">
      <div class="col-12 d-flex justify-content-between mb-4 mt-3">
        <h3><b>Clubboard</b></h3>
        <!-- <router-link class="see-all" to="/trainees">See All</router-link> -->
        <!-- <button class="btn btn-primary" @click="createGroup">
          Create Group
        </button> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
