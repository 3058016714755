<template>
  <ForgotPasswordForm />
</template>

<script>
import ForgotPasswordForm from "../components/ForgotPasswordForm.vue";

export default {
  name: "ForgotPassword",
  components: { ForgotPasswordForm },
};
</script>

<style></style>
