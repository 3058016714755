<template>
  <TopNavbar v-if="!hideNavs" />
  <router-view />
  <BottomNavbar v-if="!hideNavs" :selected="$route.name" />
</template>

<script>
import TopNavbar from "./components/TopNavbar.vue";
import BottomNavbar from "./components/BottomNavbar.vue";

import { mapGetters } from "vuex";

export default {
  name: "App",
  components: { TopNavbar, BottomNavbar },
  data() {
    return {
      hideNavs: true,
      hiddenNavRoutes: ["Login", "Register", "ForgotPassword"],
    };
  },
  computed: { ...mapGetters(["user"]) },
  watch: {
    $route: function () {
      if (!this.user && this.hiddenNavRoutes.includes(this.$route.name)) {
        this.hideNavs = true;
        this.$router.push({ name: this.$route.name });
      } else if (!this.user) {
        this.$router.push("login");
      } else if (this.user && this.hiddenNavRoutes.includes(this.$route.name)) {
        this.$router.push("/");
      } else {
        this.hideNavs = false;
      }
    },
  },
};
</script>

<style>
body {
  padding: 0;
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  padding-bottom: 7rem;
}

a {
  text-decoration: none;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none !important;
}

a:active {
  text-decoration: none;
}

.primary-button {
  background-color: #ff8556;
  border-radius: 8px;
  color: #0c0b29;
  padding: 4px 6px;
}

.multiselect__tags {
  border: 1px solid #ced4da !important;
  border-radius: 0.375rem !important;
}

.multiselect__placeholder {
  color: #828486 !important;
}

.multiselect__tag {
  background-color: #2f2da4 !important;
}

.multiselect__option--highlight {
  background-color: #2f2da4 !important;
}

.multiselect__option--highlight.multiselect__option--selected {
  background-color: #ff8556 !important;
}

.dp__theme_light {
  --dp-success-color: #2f2da4 !important;
  --dp-primary-color: #2f2da4 !important;
}
</style>
