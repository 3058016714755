<template>
  <div class="mb-3 mt-4">
    <ul
      class="items d-flex justify-content-between"
      @mousedown="start"
      @touchstart="start"
      @mousemove="move"
      @touchmove="move"
      @mouseleave="end"
      @mouseup="end"
      @touchend="end"
    >
      <li
        :class="
          selectedDay.getDate() == day.getDate() &&
          selectedDay.toLocaleString('en-us', { weekday: 'short' }) ==
            day.toLocaleString('en-us', { weekday: 'short' })
            ? 'item selected'
            : 'item'
        "
        v-for="(day, index) in days"
        :key="index"
        @click="
          () => {
            setSelectedDay(day);
            $emit('changeDay', selectedDay);
          }
        "
      >
        <p class="number">{{ day.getDate() }}</p>
        <p class="day">
          {{ day.toLocaleString("en-us", { weekday: "short" }) }}
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "EventsSlider",
  props: ["days"],
  data() {
    return {
      isDown: false,
      startX: null,
      scrollLeft: null,
      selectedDay: new Date(),
    };
  },
  methods: {
    //slider methods
    start(e) {
      const slider = document.querySelector(".items");
      this.isDown = true;
      this.startX = e.pageX || e.touches[0].pageX - slider.offsetLeft;
      this.scrollLeft = slider.scrollLeft;
    },

    end() {
      this.isDown = false;
    },

    move(e) {
      const slider = document.querySelector(".items");
      if (!this.isDown) return;

      e.preventDefault();

      const x = e.pageX || e.touches[0].pageX - slider.offsetLeft;
      const dist = x - this.startX;
      slider.scrollLeft = this.scrollLeft - dist;
    },

    scrollToMiddle() {
      const slider = document.querySelector(".items");

      if (!slider) return;

      //hardcoded slide to middle
      slider.scrollLeft = slider.offsetWidth / 2 - 180;
    },
    //end slider methods

    setSelectedDay(day) {
      this.selectedDay = day;
    },
  },

  mounted() {
    this.scrollToMiddle();
  },
};
</script>

<style scoped>
ul {
  padding: 0;
}

li {
  list-style: none;
}

.items {
  position: relative;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}

.item {
  display: inline-block;
  align-items: center;
  text-align: center;
  margin: 0 10px;
  min-width: 48px;
  min-height: 64px;
  background: #eaeaf6;
  color: #0c0b29;
  border-radius: 8px;
  padding-top: 4px;
}

.number {
  font-weight: 800;
  font-size: 23px;
  line-height: 150%;
}

.day {
  color: #7c7b8b;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 2px;
}

.item.selected {
  background: #2f2da4;
}

.item.selected > p {
  /* background: #2f2da4; */
  color: #fff;
}

/* @media screen and (min-width: 500px) {
  .item {
    width: 33%;
  }
}

@media screen and (min-width: 800px) {
  .item {
    width: 25%;
  }
}

@media screen and (min-width: 1200px) {
  .item {
    width: 20%;
  }
} */
</style>
