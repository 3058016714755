<template>
  <div className="block row mx-auto">
    <div class="basketball col-12 px-3">
      <img src="../assets/small-logo.png" class="small-logo" alt="" />
      <h3 class="mt-5 pt-3 font-weight-bold">
        <b
          >Forgot your <br />
          password?
        </b>
      </h3>
      <div class="small-line"></div>
      <router-link to="/login">
        <p class="mt-3">Already a member? <b> Login</b></p>
      </router-link>
    </div>
    <div class="form-row pt-3">
      <div class="form-group col-12">
        <label for="email"><b>Email</b></label>
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control shadow-none"
            name="email"
            placeholder="Enter your email"
          />
          <div class="input-group-append">
            <span class="input-group-text input-img-span"
              ><img src="../assets/email-icon.png" class="input-img" alt=""
            /></span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group col-12 mt-3">
      <button class="btn btn-block w-100 submit-button">
        <b>Reset Password</b>
      </button>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.block {
  width: 30%;
  min-width: 380px;
}

a {
  color: white;
}

label {
  color: #2f2da4;
}

.basketball {
  background: #2f2da4;
  height: 40%;
  background-image: url("../assets/logo-2.png");
  background-repeat: no-repeat;
  background-size: 100%;
  min-height: 240px;
  color: white;
}

.small-logo {
  position: relative;
  display: flex;
  top: 1.5rem;
  left: 0;
  width: 3rem;
}
.submit-button {
  border-radius: 8px;
  color: #0c0b29;
  background-color: #ff8556;
}

.text-right {
  text-align: right;
}

.small-line {
  width: 88px;
  height: 2px;

  background: #211f73;
  border-radius: 6px;
}

.input-img-span {
  background-color: white !important;
  border-radius: 0 0.375rem 0.375rem 0 !important;
  border-left: none;
}

input[type="text"],
input[type="password"] {
  border-right: none;
}

input:focus {
  border: 1px solid #2f2da4;
  outline: none !important;
}

.input-img {
  height: 1.5rem;
}
</style>
