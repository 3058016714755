<template>
  <div className="block row mx-auto">
    <div class="basketball col-12 px-3">
      <img src="../assets/small-logo.png" class="small-logo" alt="" />
      <h3 class="mt-5 pt-3 font-weight-bold">
        <b
          >Create an<br />
          Account
        </b>
      </h3>
      <div class="small-line"></div>
      <router-link to="/login">
        <p class="mt-3">Already a member? <b> Login</b></p>
      </router-link>
    </div>
    <div class="form-row pt-3">
      <div class="form-group col-12">
        <label for="email"><b>Full Name</b></label>
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control shadow-none"
            name="fullName"
            v-model="fullName"
            placeholder="Enter your full name"
          />
          <div class="input-group-append">
            <span class="input-group-text input-img-span"
              ><img src="../assets/name-icon.png" class="input-img" alt=""
            /></span>
          </div>
        </div>
      </div>
      <div class="form-group col-12">
        <label for="email"><b>Organization Name</b></label>
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control shadow-none"
            name="organization"
            v-model="organization"
            placeholder="Enter your organization name"
          />
          <div class="input-group-append">
            <span class="input-group-text input-img-span"
              ><img src="../assets/name-icon.png" class="input-img" alt=""
            /></span>
          </div>
        </div>
      </div>
      <div class="form-group col-12">
        <label for="email"><b>Email</b></label>
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control shadow-none"
            name="email"
            v-model="email"
            placeholder="Enter your email"
          />
          <div class="input-group-append">
            <span class="input-group-text input-img-span"
              ><img src="../assets/email-icon.png" class="input-img" alt=""
            /></span>
          </div>
        </div>
      </div>
      <div class="form-group col-12">
        <label for="password"><b>Password</b></label>
        <div class="input-group mb-3">
          <input
            type="password"
            class="form-control shadow-none"
            name="password"
            v-model="password"
            placeholder="Enter your password"
          />
          <div class="input-group-append">
            <span class="input-group-text input-img-span"
              ><img src="../assets/password-icon.png" class="input-img" alt=""
            /></span>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4 mx-auto px-0">
      <div class="col-12">
        <div class="form-check">
          <input type="checkbox" class="form-check-input" name="terms-agree" />
          <label
            class="form-check-label col-form-label-sm py-0"
            for="terms-agree"
          >
            I have read and agree to <u>Terms & Conditions.</u>
          </label>
        </div>
      </div>
    </div>
    <div class="form-group col-12">
      <div v-if="error" class="alert alert-danger mx-2" role="alert">
        The email has already been taken.
      </div>
      <button class="btn-block w-100 primary-button" @click="register">
        <b>Get Started</b>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "LoginForm",
  data() {
    return {
      fullName: "",
      organization: "",
      email: "",
      password: "",
      error: false,
    };
  },
  methods: {
    ...mapActions(["REGISTER"]),
    register() {
      let registerData = {
        fullName: this.fullName,
        organization: this.organization,
        email: this.email,
        password: this.password,
      };

      this.REGISTER(registerData)
        .then(() => {
          console.log(123);
        })
        .catch((error) => {
          this.error = true;
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.block {
  width: 30%;
  min-width: 380px;
}

a {
  color: white;
}

label {
  color: #2f2da4;
}

.basketball {
  background: #2f2da4;
  height: 40%;
  background-image: url("../assets/logo-2.png");
  background-repeat: no-repeat;
  background-size: 100%;
  min-height: 240px;
  color: white;
}

.small-logo {
  position: relative;
  display: flex;
  top: 1.5rem;
  left: 0;
  width: 3rem;
}

.text-right {
  text-align: right;
}

.small-line {
  width: 88px;
  height: 2px;

  background: #211f73;
  border-radius: 6px;
}

.input-img-span {
  background-color: white !important;
  border-radius: 0 0.375rem 0.375rem 0 !important;
  border-left: none;
}

input[type="text"],
input[type="password"] {
  border-right: none;
}

input:focus {
  border: 1px solid #2f2da4;
  outline: none !important;
}

.input-img {
  height: 1.5rem;
}
</style>
