<template>
  <table class="table table-bordered text-center">
    <thead class="table-head">
      <tr>
        <th scope="col">Name</th>
        <th scope="col">Team</th>
        <th scope="col">School</th>
        <th scope="col">Month Payment</th>
        <th scope="col">Attendance</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(trainee, index) in trainees" :key="index" class="table-row">
        <td>{{ trainee.name }}</td>
        <td>tim</td>
        <td>skola</td>
        <td>platio-nije platio</td>
        <td>prisustvo</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "TraineesTable",

  computed: {
    ...mapGetters(["trainees"]),
  },

  methods: {
    ...mapActions(["getUsers"]),
  },

  mounted() {
    if (!this.trainees.length) {
      this.getUsers();
    }
  },
};
</script>

<style>
.table-head {
  background: #2f2da4;
  color: #fff;
}

.table-row {
  background: #eaeaf6;
}

table.table-bordered {
  border: 1px solid #2f2da4;
  margin-top: 20px;
}
table.table-bordered > thead > tr > th {
  border: 1px solid #2f2da4;
}
table.table-bordered > tbody > tr > td {
  border: 1px solid #2f2da4;
}
</style>
