<template>
  <div class="col-md-6 col-sm-12 my-2">
    <router-link :to="'/groups/' + group.id">
      <div class="group d-flex px-1 py-2">
        <img src="../../assets/training-1.png" class="h-100" alt="" />
        <div class="w-100 mx-3 d-flex align-items-center">
          <div v-if="showPayments" class="horizontal-progress-bar">
            <div class="finished-horizontal-progress-bar" :style="'height:33%; top:67%;'"></div>
          </div>
          <div class="padding-left">
            <h3>{{ group.name }}</h3>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "Group",
  props: ["group", "showPayments"],
};
</script>

<style scoped>
.group {
  height: 120px;
  box-shadow: 0px 2px 10px rgba(25, 1, 52, 0.12);
  border-radius: 8px;
}

.padding-left {
  padding-left: 1rem;
}

.horizontal-progress-bar {
  width: 8px;
  height: 100%;
  background: #eaeaf6;
  border: 1px solid #aaa9da;
  border-radius: 50px;
}

.finished-horizontal-progress-bar {
  width: 8px;
  position: relative;
  left: -1px;
  background: #ea5455;
  border-radius: 50px;
}
</style>
