<template>
  <div class="nav-bar">
    <div class="nav-list">
      <router-link class="nav-list-item" to="/trainees">
        <div
          class="item"
          :style="
            selected === 'Trainees' ? 'border-bottom: 4px solid #2f2da4' : ''
          "
        >
          <img
            :src="
              selected === 'Trainees'
                ? require('../assets/trainees-selected.png')
                : require('../assets/trainees.png')
            "
            alt="Trainees"
          />
          <span :class="selected === 'Trainees' ? 'bold-text' : ''"
            >Trainees</span
          >
        </div>
      </router-link>
      <router-link class="nav-list-item" to="/groups">
        <div
          class="item"
          :style="
            selected === 'Groups' ? 'border-bottom: 4px solid #2f2da4' : ''
          "
        >
          <img
            :src="
              selected === 'Groups'
                ? require('../assets/groups-selected.png')
                : require('../assets/groups.png')
            "
            alt="Groups"
          />
          <span :class="selected === 'Groups' ? 'bold-text' : ''">Groups</span>
        </div>
      </router-link>
      <router-link class="nav-list-item" to="/payments">
        <div
          class="item"
          :style="
            selected === 'Payments' ? 'border-bottom: 4px solid #2f2da4' : ''
          "
        >
          <img
            :src="
              selected === 'Payments'
                ? require('../assets/payments-selected.png')
                : require('../assets/payments.png')
            "
            alt="Payments"
          />
          <span :class="selected === 'Payments' ? 'bold-text' : ''"
            >Payments</span
          >
        </div>
      </router-link>
      <router-link class="nav-list-item" to="/clubboard">
        <div
          class="item"
          :style="
            selected === 'Clubboard' ? 'border-bottom: 4px solid #2f2da4' : ''
          "
        >
          <img
            :src="
              selected === 'Clubboard'
                ? require('../assets/clubboard-selected.png')
                : require('../assets/clubboard.png')
            "
            alt="Clubboard"
          />
          <span :class="selected === 'Clubboard' ? 'bold-text' : ''"
            >Clubboard</span
          >
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: ["selected"],
};
</script>

<style scoped>
.nav-bar {
  position: fixed;
  z-index: 100;
  background-color: #fff;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5rem;
  z-index: 1000;
  border-top: 2px solid #8685ca;
}
.nav-list {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: 0;
}
.nav-list-item {
  text-align: center;
  color: #666;
  width: 25%;
  height: 100%;
}

i {
  text-align: center;
  font-size: 22px;
}
span {
  font-size: small;
  margin-top: 0.2em;
}

img {
  height: 2rem;
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0px;
}

.bold-text {
  color: #2f2da4;
  font-weight: 600;
}
</style>
