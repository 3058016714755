<template>
  <div class="col-sm-3 col-md-2 col-3 my-2">
    <router-link :to="'/users/' + trainee.id">
      <div class="trainee-box d-flex align-items-center px-2 py-3 text-center">
        <p>{{ trainee.name }}</p>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "TraineeBox",
  props: ["trainee"],
};
</script>

<style>
.trainee-box {
  background: #23c6a4;
  box-shadow: inset -2.82667e-16px -4.6163px 4.6163px #1eab8e,
    inset 2.82667e-16px 4.6163px 4.6163px #27dcb6;
  border-radius: 16px;
  width: 76px;
  height: 76px;
  color: #0c0b29;
}

.trainee-box > p {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 120%;
  margin: 0;
}
</style>
