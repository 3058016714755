<template>
  <div class="container">
    <div class="row">
      <div class="col-12 d-flex justify-content-between mb-4 mt-3">
        <h3><b>All Groups</b></h3>
        <button class="primary-button" @click="modalShow = !modalShow">
          <b>Create Group</b>
        </button>
      </div>
      <Group v-for="(group, index) in groups" :key="index" :group="group" />
    </div>

    <addGroupModal v-model="modalShow" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Group from "../components/Groups/Group.vue";
import addGroupModal from "../components/Groups/AddGroupModal.vue";

export default {
  name: "Groups",
  components: { Group, addGroupModal },

  data() {
    return {
      modalShow: false,
    };
  },

  computed: {
    ...mapGetters(["groups"]),
  },

  methods: {
    ...mapActions(["getGroups"]),
  },

  mounted() {
    this.getGroups();
  },
};
</script>

<style></style>
