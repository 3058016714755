<template>
  <div class="nav-bar text-center">
    <router-link to="/">
      <img src="../assets/logo.png" alt="KLUBMEN" />
    </router-link>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.nav-bar {
  position: relative;
  left: 0;
  top: 0;
  margin: 0.5em 0;
  padding: 0.5em 0;
  width: 100%;
  height: 20%;
  border-bottom: 2px solid #8685ca;
}
</style>
