<template>
  <BModal title="Add a new training" centered="true" hide-footer="true">
    <div class="row justify-content-center">
      <div class="form-group col-12">
        <label for="name"><b>Training name:</b></label>
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control shadow-none"
            name="name"
            placeholder="Enter training name"
            v-model="name"
          />
        </div>
      </div>

      <div class="form-group col-12">
        <label for="name"><b>Description:</b></label>
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control shadow-none"
            name="description"
            placeholder="Training description.."
            v-model="description"
          />
        </div>
      </div>

      <div class="form-group col-12 mb-3">
        <label for="name"><b>Training starts:</b></label>
        <Datepicker
          v-model="startDate"
          :minDate="new Date()"
          :maxDate="maxDate"
          format="dd/MM/yyyy - hh:mm"
        />
      </div>

      <div class="form-group col-12 mb-3">
        <label for="name"><b>Training ends:</b></label>
        <Datepicker
          v-model="endDate"
          :minDate="new Date()"
          :maxDate="maxDate"
          format="dd/MM/yyyy - hh:mm"
        />
      </div>

      <div class="form-group col-12">
        <label for="email"><b>Select members:</b></label>
        <div class="input-group mb-3">
          <Multiselect
            :multiple="true"
            v-model="selectedGroups"
            :options="groups"
            :searchable="false"
            :close-on-select="false"
            :show-labels="false"
            placeholder="Pick a value"
            label="name"
            track-by="name"
          ></Multiselect>
        </div>
      </div>

      <div class="col-12 mt-3">
        <div v-if="error" class="alert alert-danger mx-2" role="alert">Error!</div>
        <button class="btn-block w-100 primary-button" @click="addEvent">
          <b>Add new training</b>
        </button>
      </div>
    </div>
  </BModal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { BModal } from "bootstrap-vue-3";
import Multiselect from "vue-multiselect";
import Datepicker from "@vuepic/vue-datepicker";

export default {
  name: "AddGroupModal",
  components: {
    BModal,
    Multiselect,
    Datepicker,
  },

  data() {
    return {
      name: "",
      description: "",
      selectedGroups: [],
      startDate: Date.now() + 6000,
      endDate: Date.now(),
      error: false,
    };
  },

  props: ["minDate", "maxDate"],

  computed: {
    ...mapGetters(["groups"]),
  },

  methods: {
    ...mapActions(["createEvent", "getGroups"]),

    formatDate(date) {
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var seconds = String(date.getSeconds()).padStart(2, '0');

      return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
    },

    addEvent() {
      let trainingName = this.name;
      let description = this.description;
      let startDate = new Date(this.startDate);
      let endDate = new Date(this.endDate);

      console.log("START", this.formatDate(startDate));
      console.log("END", endDate);

      let groupIds = [];

      this.selectedGroups.forEach((group) => {
        groupIds.push(group.id);
      });

      // TODO - frontend validation
      if (!trainingName) return;

      this.createEvent({
        name: trainingName,
        description: description,
        startDate: this.formatDate(startDate),
        endDate: this.formatDate(endDate),
        groups: groupIds,
      })
        .then(() => {
          console.log(123);
          // window.location.reload();
        })
        .catch((error) => {
          console.log(error);
          this.error = true;
        });
    },
  },

  mounted() {
    this.getGroups();
  },
};
</script>

<style scoped>
input:focus {
  border: 1px solid #2f2da4;
  outline: none !important;
}
</style>
