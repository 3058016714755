<template>
  <div class="col-md-6 col-sm-12 my-2">
    <div class="training d-flex">
      <img src="../../assets/training-1.png" class="h-100" alt="" />
      <div class="w-100 d-flex align-items-center">
        <h3>{{ training.name }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UpcomingTraining",
  props: ["training"],
};
</script>

<style>
.training {
  height: 120px;
  box-shadow: 0px 2px 10px rgba(25, 1, 52, 0.12);
  border-radius: 8px;
}
</style>
