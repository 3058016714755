<template>
  <div class="container">
    <div class="row">
      <div class="col-12 d-flex justify-content-between mb-4 mt-3">
        <h3>
          <b>Grupa: {{ currentGroup?.name }}</b>
        </h3>
      </div>
      <TraineeBox
        v-for="(trainee, index) in currentGroup?.members"
        :key="index"
        :trainee="trainee"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import TraineeBox from "../components/Trainees/TraineeBox.vue";

export default {
  name: "Group",
  components: { TraineeBox },

  data() {
    return {
      group: "ok",
    };
  },

  computed: {
    ...mapGetters(["currentGroup"]),
  },

  methods: {
    ...mapActions(["getCurrentGroup"]),
  },

  async mounted() {
    let groupId = this.$route.params.id;

    await this.getCurrentGroup(groupId)
      .then(() => {})
      .catch((err) => {
        console.log(err);
        this.$router.push("/groups");
      });

    console.log(this.currentGroup);
  },
};
</script>

<style></style>
