<template>
  <RegisterForm />
</template>

<script>
import RegisterForm from "../components/RegisterForm.vue";

export default {
  name: "Register",
  components: { RegisterForm },
};
</script>

<style></style>
